import * as React from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import * as yup from "yup";
import _ from "@lodash";
import AvatarGroup from "@mui/material/AvatarGroup";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import FormHelperText from "@mui/material/FormHelperText";
import jwtService from "../../../authentication/services/jwtService";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import "./styles.css";

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
      className="forgot-spacing2"
    >
      {"Copyright © Mewahtrans GPS"}
      {/* <Link color="inherit" href="https://mui.com/">
        Your Website
      </Link> */}{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const defaultTheme = (theme) =>
  createTheme({
    ...theme,
    components: {
      MuiInputBase: {
        styleOverrides: {
          root: {
            background: "white !important",
            height: "45px",
            boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25) !important",
            padding: "0 10px !important",
          },
        },
      },
      MuiButtonBase: {
        styleOverrides: {
          root: {
            height: "45px",
            boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25) !important",
          },
        },
      },
      MuiInputLabel: {
        styleOverrides: {
          outlined: {
            transform: "translate(14px, 50%) scale(1)", // Adjust the initial position
            "&.MuiInputLabel-shrink": {
              transform: "translate(14px, -6px) scale(0.75)", // Adjust the position when shrunk (label is smaller and moved up)
            },
          },
        },
      },
    },
  });
/**
 * Form Validation Schema
 */
const schema = yup.object().shape({
  displayName: yup.string().required("You must enter display name"),
  loginID: yup.string().required("You must enter login ID"),
  email: yup
    .string()
    .email("You must enter a valid email")
    .required("You must enter a email"),
  password: yup
    .string()
    .required("Please enter your password.")
    .min(8, "Password is too short - should be 8 chars minimum."),
  passwordConfirm: yup
    .string()
    .oneOf([yup.ref("password"), null], "Passwords must match"),
  // acceptTermsConditions: yup
  //   .boolean()
  //   .oneOf([true], "The terms and conditions must be accepted."),
});

const defaultValues = {
  displayName: "",
  loginID: "",
  email: "",
  password: "",
  passwordConfirm: "",
  // acceptTermsConditions: false,
};

export default function SignUpPage() {
  const { control, formState, handleSubmit, reset } = useForm({
    mode: "onChange",
    defaultValues,
    resolver: yupResolver(schema),
  });

  const { isValid, dirtyFields, errors, setError } = formState;

  function onSubmit({ displayName, loginID, password, email }) {
    jwtService
      .createUser({
        displayName,
        loginID,
        password,
        email,
      })
      .then((user) => {
        // No need to do anything, registered user data will be set at app/auth/AuthContext
      })
      .catch((_errors) => {
        _errors.forEach((error) => {
          setError(error.type, {
            type: "manual",
            message: error.message,
          });
        });
      });
  }

  return (
    <ThemeProvider theme={defaultTheme}>
      <div
        style={{
          width: "100%",
          height: "100vh",
          display: "grid",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Container component="main" maxWidth="xs">
          <CssBaseline />
          <Box
            sx={{
              marginTop: 8,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Avatar
              sx={{ width: 110, height: 110, m: 1, bgcolor: "secondary.main" }}
              src={require("@assets/images/appicon.png")}
            />
            <Typography component="h1" variant="h5" className="forgot-spacing2">
              Register Account
            </Typography>
            <Box
              component="form"
              noValidate
              onSubmit={handleSubmit(onSubmit)}
              sx={{ mt: 3 }}
            >
              <Grid container maxWidth="xs">
                <Grid item xs={12} className="forgot-spacing2">
                  <Controller
                    name="displayName"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        required
                        fullWidth
                        id="displayName"
                        label="Display Name"
                        name="displayName"
                        error={!!errors.displayName}
                        helperText={errors?.displayName?.message}
                        className="textfield-height"
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} className="forgot-spacing2">
                  <Controller
                    name="loginID"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        required
                        fullWidth
                        id="loginID"
                        label="Login ID"
                        name="loginID"
                        error={!!errors.loginID}
                        helperText={errors?.loginID?.message}
                        className="textfield-height"
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} className="forgot-spacing2">
                  <Controller
                    name="email"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        required
                        fullWidth
                        id="email"
                        label="Email Address"
                        name="email"
                        autoComplete="email"
                        error={!!errors.email}
                        helperText={errors?.email?.message}
                        className="textfield-height"
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} className="forgot-spacing">
                  <Controller
                    name="password"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        required
                        fullWidth
                        name="password"
                        label="New Password"
                        type="password"
                        autoComplete="new-password"
                        id="password"
                        error={!!errors.password}
                        helperText={errors?.password?.message}
                        className="textfield-height"
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} className="forgot-spacing">
                  <Controller
                    name="passwordConfirm"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        required
                        fullWidth
                        name="password-confirm"
                        label="Confirm Password"
                        type="password"
                        autoComplete="new-password"
                        id="passwordConfirm"
                        error={!!errors.passwordConfirm}
                        helperText={errors?.passwordConfirm?.message}
                        className="textfield-height"
                      />
                    )}
                  />
                </Grid>
              </Grid>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3 }}
                className="forgot-spacing2"
              >
                Register
              </Button>
              <Link href="/" variant="body2">
                <Button
                fullWidth
                variant="contained"
                sx={{ mt: 3 }}
                className="forgot-spacing2"
              >
                Back
              </Button>
              </Link>
            </Box>
          </Box>
          <Copyright sx={{ mt: 5 }} />
        </Container>
      </div>
    </ThemeProvider>
  );
}

// export default SignUpPage;
