import React from "react";
import "./style.css";
import { InfoWindow } from "@react-google-maps/api";
import { timestampConvert, sec2Time } from "@utils";
import { Icon } from "@iconify/react";
import { COLORS } from "@constants";

const mapInfoWindow = (props) => {
  const {
    marker,
    markersOnClick,
    // onClickFunc,
    anchor,
    setIsOverInfoWindow,
    hideInfoWindowTimeout,
    setSelectedMarker,
    setTrackDetails,
  } = props;

  return (
    <InfoWindow anchor={anchor}>
      <div
        id="iw-container"
        onClick={() => (markersOnClick ? setTrackDetails(marker.id) : null)}
        onMouseOver={() => {
          if (hideInfoWindowTimeout.current) {
            clearTimeout(hideInfoWindowTimeout.current);
          }
          setIsOverInfoWindow(true);
          setSelectedMarker(marker);
        }}
        onMouseOut={() => {
          setIsOverInfoWindow(false);
          hideInfoWindowTimeout.current = setTimeout(() => {
            setSelectedMarker(null);
          }, 200);
        }}
        style={{ cursor: "pointer" }}
      >
        <div className="iw-title">
          Last Reading: {timestampConvert(marker.timestamp)} <br />
          Status:{" "}
          <span>
            {marker.ACC === "1" && marker.speed != "0" ? (
              <Icon
                icon="carbon:play-filled"
                color={COLORS.green}
                className="icon-style"
                width="18"
                height="18"
              />
            ) : marker.ACC === "1" && marker.speed === "0" ? (
              <Icon
                icon="mingcute:moon-fill"
                color={COLORS.yellow}
                className="icon-style"
                width="18"
                height="18"
              />
            ) : (
              <Icon
                icon="carbon:stop-filled"
                color={COLORS.red}
                className="icon-style"
                width="18"
                height="18"
              />
            )}
          </span>
        </div>
        <div className="iw-content">
          <div
            className="circle"
            style={{
              background:
                marker.ACC === "0"
                  ? COLORS.red
                  : marker.ACC === "1" && parseInt(marker.speed) > 0
                  ? COLORS.green
                  : COLORS.yellow,
              color:
                marker.ACC === "0"
                  ? COLORS.white
                  : marker.ACC === "1" && parseInt(marker.speed) > 0
                  ? COLORS.black
                  : COLORS.black,
            }}
          >
            {marker.speed} km/h
          </div>
          <p>
            Prime Mover: {marker.vehiclename ? marker.vehiclename : "-"} <br />
            Reg No. : {marker.PlateNumber ? marker.PlateNumber : "-"} <br />
            Location: {marker.address ? marker.address : "-"} <br />
            Today's Idling Duration: {marker.total_idling_seconds ? sec2Time(marker.total_idling_seconds) : "-"} <br />
          </p>
        </div>
        {/* <div className="iw-bottom-gradient"></div> */}
      </div>
    </InfoWindow>
  );
};

export default mapInfoWindow;
