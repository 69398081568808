import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
} from "@reduxjs/toolkit";
import axios from "axios";
import * as Models from "./models";
import * as Config from "./serverConfig";

export const getLatestContinuousIdlingTime = createAsyncThunk(
  "trucks/getLatestContinuousIdlingTime",
  async () => {
    const link = Config.Server?.link + "mode=getLatestContinuousIdlingTime";
    let dataList = [];

    const response = await axios({
      method: "get",
      url: link,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    });
    // const data = await response.data;

    // return data === undefined ? null : data;
    const json = await response.data;

    if (json.success) {
      dataList = json.data.map((item) => {
        return new Models.LatestContinuousIdlingTimeModel(item);
      });
      return dataList;
    } else {
      return { success: json.success };
    }

    // return data;
  }
);

const Adapter = createEntityAdapter({});

export const {
  selectAll: selectLatestContinuousIdlingTime,
  selectById: selectLatestContinuousIdlingTimeById,
} = Adapter.getSelectors((state) => state.latestContinuousIdlingTime);

const Slice = createSlice({
  name: "latestContinuousIdlingTime",
  initialState: Adapter.getInitialState({
    searchText: "",
  }),
  reducers: {
    setTrucksSearchText: {
      reducer: (state, action) => {
        state.searchText = action.payload;
      },
      prepare: (event) => ({ payload: event.target.value || "" }),
    },
  },
  extraReducers: {
    [getLatestContinuousIdlingTime.fulfilled]: Adapter.setAll,
    // [removeMerchants.fulfilled]: (state, action) =>
    //   Adapter.removeMany(state, action.payload),
  },
});

export const { setLatestContinuousIdlingTime } = Slice.actions;

export const selectLatestContinuousIdlingTimeSearchText = ({
  latestContinuousIdlingTime,
}) => latestContinuousIdlingTime.searchText;

export default Slice.reducer;
