const DEFAULT_MAP_CENTER = { lat: 1.716799, lng: 103.711628 };

const COMPANY_MAP_LOC = { lat: 1.478881, lng: 103.9271033 };

const MAX_SNACK = 2;

const DISABLEMAPS = false;

const DEFAULT_OVER_SPEED_WARNING = true;

const DEFAULT_OVER_SPEED_LIMIT = 80; // at server

const DEFAULT_IDLE_LIMIT_WARNING = false;

const DEFAULT_ARRIVED_WARNING = false;

const MAP_REFRESH_RATE = 180000; // 3 mins // at server

const DATA_REFRESH_RATE = 60000; // 1 mins // at server

const DEFAULT_IDLE_LIMIT_TIME = 1800 // 30 mins (in seconds) // at server

const DEFAULT_SNACKBAR_HIDE_DURATION = 5000; // 5 secs // at server

const DEFAULT_CRITICAL_FUEL_CONSUMPTION_PER_MIN = 0.65; // L/MIN (0.65 when 120km/h, 0.45 when 90km/h)
// 0.3 L/KM (normal consumption) x 90KM/H (speed) / 60 min = 0.45 L/MIN // at server

const DEFAULT_FUEL_THEFT_WARNING = true;

export {
  DEFAULT_MAP_CENTER,
  COMPANY_MAP_LOC,
  MAX_SNACK,
  DISABLEMAPS,
  DEFAULT_OVER_SPEED_WARNING,
  DEFAULT_OVER_SPEED_LIMIT,
  DEFAULT_IDLE_LIMIT_WARNING,
  DEFAULT_ARRIVED_WARNING,
  MAP_REFRESH_RATE,
  DATA_REFRESH_RATE,
  DEFAULT_IDLE_LIMIT_TIME,
  DEFAULT_SNACKBAR_HIDE_DURATION,
  DEFAULT_CRITICAL_FUEL_CONSUMPTION_PER_MIN,
  DEFAULT_FUEL_THEFT_WARNING,
};
