import React, {
  useMemo,
  useEffect,
  useState,
  useCallback,
  useRef,
} from "react";
import {
  GoogleMap,
  Marker,
  Polyline,
} from "@react-google-maps/api";
import TruckRight from "@assets/images/LORRY1.png";
import TruckLeft from "@assets/images/LORRY2.png";
import MapInfoWindow from "../mapInfoWindow";
import { rotateImage, getDistanceOnMap, isMobileDevice } from "@utils";
import { DEFAULT_MAP_CENTER, COLORS } from "@constants";
// import Stop_Left_Truck from "@assets/images/stop_left_lorry1.png";
// import Stop_Right_Truck from "@assets/images/stop_right_lorry1.png";
// import Idle_Left_Truck from "@assets/images/idle_left_lorry1.png";
// import Idle_Right_Truck from "@assets/images/idle_right_lorry1.png";
// import Run_Left_Truck from "@assets/images/run_left_lorry1.png";
// import Run_Right_Truck from "@assets/images/run_right_lorry1.png";
// import Stop_Truck from "@assets/images/stop_lorry.png";
// import Idle_Truck from "@assets/images/idle_lorry.png";
// import Run_Truck from "@assets/images/run_lorry.png";
// import Default_Truck from "@assets/images/default_lorry.png";
import Stop_Truck from "@assets/images/stop_arrow.png";
import Idle_Truck from "@assets/images/idle_arrow.png";
import Run_Truck from "@assets/images/run_arrow.png";
import Default_Truck from "@assets/images/default_arrow.png";

const Trucks = {
  // stop: {
  //   left: Stop_Left_Truck,
  //   right: Stop_Right_Truck,
  // },
  // idle: {
  //   left: Idle_Left_Truck,
  //   right: Idle_Right_Truck,
  // },
  // run: {
  //   left: Run_Left_Truck,
  //   right: Run_Right_Truck,
  // },
  stop: Stop_Truck,
  idle: Idle_Truck,
  run: Run_Truck,
  default: Default_Truck,
};

const markerIconHandler = (item) => {
  let status = "";
  let heading = "";
  if (item.ACC === "1" && item.speed != "0") {
    status = "run";
  } else if (item.ACC === "1" && item.speed === "0") {
    status = "idle";
  } else {
    status = "stop";
  }

  // if (item.direction < 180) {
  //   heading = "left";
  // } else {
  //   heading = "right";
  // }

  // return Trucks[status][heading];
  return Trucks[status];
};

const CustomMaps = (props) => {
  const [mapView, setMapView] = useState(null);
  const [rotatedIcons, setRotatedIcons] = useState({});
  const [selectedMarker, setSelectedMarker] = useState(null);
  const [isOverInfoWindow, setIsOverInfoWindow] = useState(false);
  const overlaysRef = useRef([]);
  const hideInfoWindowTimeout = useRef(null);
  const [showTraffic, setShowTraffic] = useState(false); // State to manage traffic layer visibility
  const trafficLayerRef = useRef(null);
  const showTrafficRef = useRef(showTraffic);
  const [userHasInteracted, setUserHasInteracted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const refreshControlRef = useRef(null);
  const refreshControlTextRef = useRef(null);

  const defaultCenter = useMemo(() => DEFAULT_MAP_CENTER, []); // Default map area
  const center = props?.center ? props?.center : defaultCenter;
  const setTrackDetails = props?.setTrackDetails;
  const directions = props?.directions;
  const zoom = props?.zoom ? props?.zoom : 10;
  const [currentZoom, setCurrentZoom] = useState(zoom);
  const mapContainerStyle = props?.mapContainerStyle
    ? props?.mapContainerStyle
    : { width: "100%", height: "100%" };
  const options = props?.options
    ? props?.options
    : {
      zoomControl: true,
      streetViewControl: false,
      mapTypeControl: false,
      fullscreenControl: false,
      // styles: [
      //   {
      //     elementType: "labels",
      //     featureType: "poi.business",
      //     stylers: [{ visibility: "off" }],
      //   },
      // ],
    };
  const markersWindowHover = props?.markersWindowHover;
  const markersOnClick = props?.markersOnClick;
  const data = props?.data;
  const customMarker = props?.customMarker;
  const fromTruckDetails = props?.fromTruckDetails;
  const polygonOptions = props?.polygonOptions
    ? props?.polygonOptions
    : {
      strokeColor: "yellow",
      strokeOpacity: 0.5,
      strokeWeight: 3.0,
      fillColor: "red",
      fillOpacity: 0.2,
    };
  const polygonPath = props?.polygonPath ? props?.polygonPath : null;
  const polygonLine = props?.polygonLine ? props?.polygonLine : null;
  const markersLabel = props?.markersLabel ? props?.markersLabel : false;
  //   const polygonLine = [
  //     {
  //         "lat": 1.4759166666666665,
  //         "lng": 103.92321666666666,
  //     },
  //     {
  //         "lat": 1.4790166666666666,
  //         "lng": 103.92795,
  //     },
  //     {
  //         "lat": 1.4789833333333333,
  //         "lng": 103.92761666666667,
  //     },
  //     {
  //         "lat": 1.4791666666666667,
  //         "lng": 103.92788333333333,
  //     },
  //     {
  //         "lat": 1.4791333333333334,
  //         "lng": 103.92786666666666,
  //     },
  //     {
  //         "lat": 1.47895,
  //         "lng": 103.92768333333333,
  //     },
  //     {
  //         "lat": 1.4789666666666665,
  //         "lng": 103.92785,
  //     },
  //     {
  //         "lat": 1.47905,
  //         "lng": 103.92786666666666,
  //     },
  //     {
  //         "lat": 1.4789333333333334,
  //         "lng": 103.92773333333334,
  //     },
  //     {
  //         "lat": 1.479,
  //         "lng": 103.92766666666667,
  //     },
  //     {
  //         "lat": 1.4790999999999999,
  //         "lng": 103.92788333333333,
  //     },
  //     {
  //         "lat": 1.4790999999999999,
  //         "lng": 103.9279,
  //     },
  //     {
  //         "lat": 1.4789333333333334,
  //         "lng": 103.9277,
  //     },
  //     {
  //         "lat": 1.4789,
  //         "lng": 103.92765,
  //     },
  //     {
  //         "lat": 1.4791166666666666,
  //         "lng": 103.92785,
  //     },
  //     {
  //         "lat": 1.4790999999999999,
  //         "lng": 103.92786666666666,
  //     }
  // ];

  const handleToggleTraffic = () => {
    setShowTraffic(!showTraffic); // Toggle the state to show/hide traffic layer
  };

  useEffect(() => {
    showTrafficRef.current = showTraffic;
  }, [showTraffic]);

  const createTrafficToggleButton = (mapView) => {
    const controlDiv = document.createElement("div");

    // Style the control border and background
    controlDiv.style.backgroundColor = "#fff";
    controlDiv.style.border = "2px solid #fff";
    controlDiv.style.borderRadius = "3px";
    controlDiv.style.boxShadow = "0 2px 6px rgba(0,0,0,.3)";
    controlDiv.style.cursor = "pointer";
    controlDiv.style.marginBottom = "22px";
    controlDiv.style.setProperty('margin-top', '6px', 'important');
    controlDiv.style.textAlign = "center";
    controlDiv.style.setProperty('margin-left', '6px', 'important');

    // Set CSS for the control interior
    const controlText = document.createElement("div");
    controlText.style.color = "rgb(25,25,25)";
    controlText.style.fontFamily = "Roboto,Arial,sans-serif";
    controlText.style.fontSize = "16px";
    controlText.style.lineHeight = "38px";
    controlText.style.setProperty('margin-left', '10px', 'important');
    controlText.style.setProperty('margin-right', '10px', 'important');
    controlText.style.setProperty('width', '98px', 'important');
    controlText.innerHTML = "Show Traffic";
    controlDiv.appendChild(controlText);

    controlDiv.addEventListener("click", () => {
      const shouldShowTraffic = !showTrafficRef.current;
      setShowTraffic(shouldShowTraffic);
      controlText.innerHTML = shouldShowTraffic
        ? "Hide Traffic"
        : "Show Traffic";

      if (shouldShowTraffic) {
        trafficLayerRef.current = new window.google.maps.TrafficLayer();
        trafficLayerRef.current.setMap(mapView);
      } else {
        if (trafficLayerRef.current) {
          trafficLayerRef.current.setMap(null);
        }
      }
    });

    controlDiv.index = 1;
    mapView.controls[window.google.maps.ControlPosition.TOP_LEFT].push(
      controlDiv
    );
  };

  const createRefreshButton = (mapView) => {
    if (refreshControlRef.current) {
      mapView.controls[window.google.maps.ControlPosition.TOP_LEFT].remove(refreshControlRef.current);
    }

    const controlDiv = document.createElement("div");

    // Style the control border and background
    controlDiv.style.backgroundColor = "#fff";
    controlDiv.style.border = "2px solid #fff";
    controlDiv.style.borderRadius = "3px";
    controlDiv.style.boxShadow = "0 2px 6px rgba(0,0,0,.3)";
    controlDiv.style.cursor = "pointer";
    controlDiv.style.marginBottom = "22px";
    controlDiv.style.setProperty('margin-top', '6px', 'important');
    controlDiv.style.textAlign = "center";
    controlDiv.style.setProperty('margin-left', '6px', 'important');

    // Set CSS for the control interior
    const controlText = document.createElement("div");
    controlText.style.color = "rgb(25,25,25)";
    controlText.style.fontFamily = "Roboto,Arial,sans-serif";
    controlText.style.fontSize = "16px";
    controlText.style.lineHeight = "38px";
    controlText.style.setProperty('margin-left', '10px', 'important');
    controlText.style.setProperty('margin-right', '10px', 'important');
    controlText.style.setProperty('width', '98px', 'important');
    controlText.innerHTML = isLoading ? 'Refreshing...' : 'Refresh';;

    if (isLoading) {
      const spinner = document.createElement("span");
      spinner.className = "spinner-border spinner-border-sm";
      spinner.role = "status";
      spinner.ariaHidden = "true";
      controlText.appendChild(spinner);
    }
    controlDiv.appendChild(controlText);

    controlDiv.addEventListener("click", () => {
      if (!isLoading) {  // Prevent additional clicks when already loading
        refreshMarkerData();
      }
    });

    controlDiv.index = 1;

    refreshControlRef.current = controlDiv;
    refreshControlTextRef.current = controlText;

    mapView.controls[window.google.maps.ControlPosition.TOP_LEFT].push(
      controlDiv
    );
  };

  const onLoad = useCallback((mapInstance) => {
    setMapView(mapInstance);
    createTrafficToggleButton(mapInstance);
    if (props.onRefreshData) {
      createRefreshButton(mapInstance);
    }

    setTimeout(() => {
      const handleUserInteraction = () => {
        if (!fromTruckDetails) {
          setUserHasInteracted(true);
        }
      };

      // Add event listeners for zoom_changed and dragend after a delay
      mapInstance.addListener('zoom_changed', handleUserInteraction);
      mapInstance.addListener('dragend', handleUserInteraction);
    }, 3000);
  }, [userHasInteracted]);

  const fitBoundsToMarkers = useCallback(() => {
    if (mapView && fromTruckDetails && !userHasInteracted) {
      mapView.setCenter(data[0].markerPosition);
      mapView.setZoom(zoom + 4);
    } else if (mapView && data.length > 0 && !polygonLine) {
      const bounds = new window.google.maps.LatLngBounds();

      data.forEach((item) => {
        bounds.extend(item.markerPosition);
      });

      mapView.fitBounds(bounds);
      if (data.length === 1) {
        mapView.setZoom(zoom + 4);
      } else {
        const tooCloseThreshold = 0.1; // 100km
        let allMarkersAreTooClose = true;

        // Auto adjust zoom level by the markers position
        // It may cause optimize issue
        for (let i = 0; i < data.length; i++) {
          for (let j = i + 1; j < data.length; j++) {
            const distance = getDistanceOnMap(
              data[i].markerPosition,
              data[j].markerPosition
            );
            if (distance >= tooCloseThreshold) {
              allMarkersAreTooClose = false;
              break;
            }
          }
        }

        if (allMarkersAreTooClose) {
          mapView.setZoom(zoom + 4);
        }
      }
    } else if (mapView && polygonLine) {
      const bounds = new window.google.maps.LatLngBounds();

      polygonLine.forEach((item) => {
        bounds.extend({ lat: parseFloat(item.lat), lng: parseFloat(item.lng) });
      });

      mapView.fitBounds(bounds);
      if (polygonLine.length === 1) {
        mapView.setZoom(zoom + 4);
      }
    } else if (mapView) {
      mapView.setCenter(center);
      mapView.setZoom(zoom);
    }
  }, [mapView, data, userHasInteracted]);

  class CustomLabelOverlay extends window.google.maps.OverlayView {
    constructor(position, text) {
      super();
      this.position = position;
      this.text = text;
      this.div = null;
    }

    onAdd() {
      this.div = document.createElement("div");
      this.div.style.position = "absolute";
      this.div.style.padding = "9px";
      this.div.style.marginTop = "10px";
      this.div.style.backgroundColor = "rgba(30, 30, 30, 60%)";
      this.div.style.border = "1px solid rgba(30, 30, 30, 60%)";
      this.div.style.borderRadius = "3px";
      this.div.style.fontSize = "15px";
      this.div.style.fontWeight = "bold";
      this.div.style.color = "white";
      this.div.style.whiteSpace = "nowrap";
      this.div.style.zIndex = "1";
      this.div.innerHTML = this.text;

      let panes = this.getPanes();
      panes.overlayLayer.appendChild(this.div);
    }

    draw() {
      let overlayProjection = this.getProjection();
      let position = overlayProjection.fromLatLngToDivPixel(this.position);

      // this.div.style.left = position.x - 35 + "px";
      // this.div.style.top = position.y + 15 + "px";
      this.div.style.left = position.x - (this.div.offsetWidth / 2) + 'px';
      this.div.style.top = position.y + this.div.offsetHeight - 5 + 'px';
    }

    onRemove() {
      if (this.div) {
        this.div.parentNode.removeChild(this.div);
        this.div = null;
      }
    }
  }

  useEffect(() => {
    if (!userHasInteracted) {
      fitBoundsToMarkers();
    }
  }, [fitBoundsToMarkers, data, userHasInteracted]);

  const resetViewToFitMarkers = () => {
    setUserHasInteracted(false); // Allow automatic adjustment again if needed
    fitBoundsToMarkers();
  };

  useEffect(() => {
    if (refreshControlTextRef.current) {
      refreshControlTextRef.current.innerHTML = isLoading ? 'Refreshing...' : 'Refresh';
      if (isLoading) {
        const spinner = document.createElement("span");
        spinner.className = "spinner-border spinner-border-sm";
        refreshControlTextRef.current.appendChild(spinner);
      } else {
        // Remove the spinner if it exists
        const existingSpinner = refreshControlTextRef.current.querySelector('.spinner-border');
        if (existingSpinner) {
          refreshControlTextRef.current.removeChild(existingSpinner);
        }
      }
    }
  }, [isLoading]);

  const refreshMarkerData = () => {
    // Implement your logic to refresh marker data
    // For example, you might call a prop function or fetch new data here
    // console.log(props.onRefreshData)
    setIsLoading(true);
    if (props.onRefreshData) {
      props.onRefreshData()
        .finally(() => {
          setIsLoading(false); // Reset loading state when the refresh is done
        });
    } else {
      // Simulate a fetch or async operation
      setTimeout(() => {
        setIsLoading(false);  // Reset loading state after some operation
      }, 2000);  // Mock delay
    }
  };

  useEffect(() => {
    if (mapView && data && markersLabel) {
      overlaysRef.current.forEach((overlay) => overlay.setMap(null));
      overlaysRef.current = [];

      data.forEach((item) => {
        const overlay = new CustomLabelOverlay(
          new window.google.maps.LatLng(
            item.markerPosition.lat,
            item.markerPosition.lng
          ),
          item.vehiclename + "(" + item.PlateNumber + ")"// Text to display
        );

        overlay.setMap(mapView);
        overlaysRef.current.push(overlay);
      });
    }
  }, [mapView, data]);

  useEffect(() => {
    const loadAndRotateImages = async () => {
      const newRotatedIcons = {};
      await Promise.all(props.data.map(async (item) => {
        const rotatedUrl = await rotateImage(markerIconHandler(item), item.direction);
        newRotatedIcons[item.id] = rotatedUrl;
      }));
      setRotatedIcons(newRotatedIcons);
    };

    loadAndRotateImages();
  }, [props.data]);

  useEffect(() => {
    try {
      if (!mapView) return;

      if (showTraffic) {
        trafficLayerRef.current = new window.google.maps.TrafficLayer();
        trafficLayerRef.current.setMap(mapView);
      } else {
        if (trafficLayerRef.current) {
          trafficLayerRef.current.setMap(null);
        }
      }
    } catch (error) {
      console.error("Error toggling traffic layer:", error);
    }
  }, [showTraffic, mapView]);

  // console.log(allMarkersView);
  return (
    <>
      <GoogleMap
        zoom={currentZoom}
        center={center}
        bootstrapURLKeys={process.env.REACT_APP_GOOGLE_MAP_API_KEY}
        yesIWantToUseGoogleMapApiInternals
        mapContainerStyle={mapContainerStyle}
        options={options}
        onLoad={onLoad}
      // key={data[0]?.timestamp}
      // shouldUnregister={false}
      >
        {/* {console.log(customMarker)} */}
        {polygonLine === null &&
          data?.map((item, index) => (
            <div id="marker-map" key={index}>
              {/* {console.log(item.direction)} */}
              <Marker
                position={item.markerPosition}
                onMouseOver={() => {
                  if (markersWindowHover) {
                    if (hideInfoWindowTimeout.current) {
                      clearTimeout(hideInfoWindowTimeout.current);
                    }
                    setIsOverInfoWindow(false);
                    setSelectedMarker(item);
                  }
                }}
                onMouseOut={() =>
                  markersWindowHover
                    ? (hideInfoWindowTimeout.current = setTimeout(() => {
                      if (!isOverInfoWindow) {
                        setSelectedMarker(null);
                      }
                    }, 200))
                    : null
                }
                onClick={() => {
                  if (isMobileDevice() && window.innerWidth <= 900) {
                    setSelectedMarker(item);
                  }
                  if (markersOnClick && !isMobileDevice()) {
                    setTrackDetails(item.id);
                  }
                }}
                {...(customMarker && {
                  icon: {
                    // url: markerIconHandler(customMarker, item),
                    url: rotatedIcons[item.id] || Default_Truck,
                    scaledSize: new window.google.maps.Size(45, 45),
                    origin: new window.google.maps.Point(0, 0), // Origin of the icon, by default at the top left corner
                    anchor: new window.google.maps.Point(22.5, 22.5),
                    // item.direction < 180
                    //   ? customMarker.right_image
                    //   : customMarker.left_image,
                  },
                })}
              // icon={{
              //   url: item.direction < 180 ? TruckRight : TruckLeft,
              //   scaledSize: new window.google.maps.Size(30, 30),
              //   rotation: item.direction < 180 ? 0 : 180,
              //   anchor: new window.google.maps.Point(50, 50),
              // }}
              // options={{
              //   icon: {
              //     url: rotateIcon
              //       .makeIcon(LOGO)
              //       .setRotation({ deg: item.direction })
              //       .getUrl(),
              //   },
              // }}
              // options={{
              //   icon: {
              //     url: flipIcon.makeIcon(LOGO).setFlip(item.direction < 180 ? false : true, false).getUrl(),
              //   },
              // }}
              >
                {selectedMarker?.id === item.id && (
                  <MapInfoWindow
                    {...props}
                    marker={item}
                    setTrackDetails={setTrackDetails}
                    setIsOverInfoWindow={setIsOverInfoWindow}
                    setSelectedMarker={setSelectedMarker}
                    hideInfoWindowTimeout={hideInfoWindowTimeout}
                  />
                )}
              </Marker>
            </div>
          ))}
        {polygonLine && (
          <div>
            <Polyline
              path={polygonLine}
              geodesic={true}
              options={{
                strokeColor: COLORS.blue,
                strokeOpacity: 0.75,
                strokeWeight: 2,
                icons: [
                  {
                    icon: {
                      path: window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
                    },
                    offset: "100%",
                    repeat: "50px",
                  },
                ],
              }}
            />
            <Marker
              position={{
                lat: parseFloat(polygonLine[0].lat),
                lng: parseFloat(polygonLine[0].lng),
              }}
              icon={{
                url: "http://maps.google.com/mapfiles/ms/icons/red-pushpin.png",
              }}
            ></Marker>
            <Marker
              position={{
                lat: parseFloat(polygonLine[polygonLine.length - 1].lat),
                lng: parseFloat(polygonLine[polygonLine.length - 1].lng),
              }}
              icon={{
                url: polygonLine.direction < 180 ? TruckRight : TruckLeft,
              }}
            ></Marker>
          </div>
        )}
        {/* {directions && <DirectionsRenderer directions={directions} />} */}
      </GoogleMap>
    </>
  );
};

export default CustomMaps;
