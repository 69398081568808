import { lazy } from "react";
// import { Navigate } from "react-router-dom";

const Location = lazy(() => import("./locationPage"));
const Report = lazy(() => import("./reportPage"));
const Setting = lazy(() => import("./settingPage"));
const PageNotFound = lazy(() => import("./pageNotFound"));

const PagesConfig = [{
  settings: {
    layout: {},
  },
  routes: [
    {
      path: "location",
      element: <Location />,
    },
    {
      path: "report",
      element: <Report />,
    },
    {
      path: "setting",
      element: <Setting />,
    },
    {
      path: "pagenotfound",
      element: <PageNotFound />,
    },
    {
      path: "api/",
      element: <PageNotFound />,
    },
    {
      path: "api/*",
      element: <PageNotFound />,
    },
  ],
}];

export default PagesConfig;
