export default class TruckRouteModel {
  constructor(item) {
    this.id = item?.Ref;
    this.truck_id = item?.ID;
    this.time_interval = item?.time_interval;
    this.timestamp = item?.timestamp;
    this.speed = item?.speed;
    this.lat = parseFloat(item?.lat);
    this.lng = parseFloat(item?.lng);
    this.milage = item?.milage;
    this.oilres = item?.oilres;
    this.oillvl = item?.oillvl;
    this.caseUnit = item?.caseUnit;
    this.direction = item?.direction;
    this.PlateNumber = item?.PlateNumber;
    this.DriverName = item?.DriverName;
    this.IC = item?.IC;
    this.vehiclename = item?.vehiclename;
  }
}