var FlipIcon = function(options){
  this.options = options || {};
  this.rImg = options.img || new Image();
  this.options.width = this.options.width || this.rImg.width || 52;
  this.options.height = this.options.height || this.rImg.height || 60;
  var canvas = document.createElement("canvas");
  canvas.width = this.options.width;
  canvas.height = this.options.height;
  this.context = canvas.getContext("2d");
  this.canvas = canvas;

  // Ensure the image loads before trying to manipulate it.
  this.rImg.onload = () => {
    this.context.drawImage(this.rImg, 0, 0);
  };

  this.rImg.src = this.rImg.src || this.options.url || '';
};

FlipIcon.makeIcon = function(url) {
  return new FlipIcon({url: url});
};

FlipIcon.prototype.setFlip = function(flipH, flipV){
  var canvas = this.context,
      scaleH = flipH ? -1 : 1,
      scaleV = flipV ? -1 : 1,
      posX = flipH ? this.options.width * -1 : 0,
      posY = flipV ? this.options.height * -1 : 0;

  canvas.save();
  canvas.scale(scaleH, scaleV);
  canvas.drawImage(this.rImg, posX, posY, this.options.width, this.options.height);
  canvas.restore();
  return this;
};

FlipIcon.prototype.getUrl = function(){
  return this.canvas.toDataURL('image/png');
};

export default FlipIcon;
