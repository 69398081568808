import { combineReducers } from "@reduxjs/toolkit";
import trucksReducer from "./trucksSlice";
import truckDateRangeReducer from "./truckDateRangeSlice";
import truckRouteHistoryReducer from "./truckRouteHistorySlice";
import truckStatsReportReducer from "./truckStatsSlice";
import latestContinuousIdlingTimeReducer from "./latestContinuousIdlingTime";
import user from "./userSlice";
import monitorLog from "./monitorLogSlice";

const reducers = {
  trucks: trucksReducer,
  truckStats: truckDateRangeReducer,
  truckRoute: truckRouteHistoryReducer,
  truckStatsReport: truckStatsReportReducer,
  latestContinuousIdlingTime: latestContinuousIdlingTimeReducer,
  user: user,
  monitorLog: monitorLog,
};

export default reducers;