import React, { useState } from "react";
import { Grid, Box, Button, Typography } from "@mui/material";
import { Header, Container, Sidebar } from "@components";
import { DrawingTool, MultiScreen, SoundSystem } from "./components";
import "./style.css";

const SettingPage = () => {
  const [activeTab, setActiveTab] = useState(1);
  const [tabs, setTabs] = useState([
    {
      _id: 1,
      title: "Multi Screen",
      is_active: true,
    },
    {
      _id: 2,
      title: "Sound / Alert System Setting",
      is_active: false,
    },
    {
      _id: 3,
      title: "Drawing Tools",
      is_active: false,
    },
  ]);
  return (
    <React.Fragment>
      <Header />
      <Grid container>
        <Grid item xs={12} className="setting_tabs_container">
          <Grid container>
            {tabs?.map((item) => (
              <Grid item xs={12} md={(12 / tabs.length)} key={item?._id}>
                <Grid container>
                  <Grid item xs={0} md={0.5}></Grid>
                  <Grid item xs={12} md={11}>
                    <Button
                      className={
                        activeTab === item?._id
                          ? "setting_tab_active"
                          : "setting_tab"
                      }
                      onClick={() => setActiveTab(item._id)}
                    >
                      {item?.title}
                    </Button>
                  </Grid>
                  <Grid item xs={0} md={0.5}></Grid>
                </Grid>
              </Grid>
            ))}
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={12}>
              {activeTab === 1 ? (
                <MultiScreen />
              ) : activeTab === 2 ? (
                <SoundSystem />
              ) : (
                <DrawingTool />
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};
export default SettingPage;
