import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
} from "@reduxjs/toolkit";
import axios from "axios";
import * as Models from "./models";
import * as Config from "./serverConfig";
import { AddressFromLatLng } from "./AddressFromLatLng";
import { OpenReserveGeocoding } from "./OpenReverseGeocoding";

export const getTruckStatRange = createAsyncThunk(
  "trucks/getTruckStatRange",
  async (props) => {
    const link = Config.Server?.link + "mode=getTruckStatRange";
    let dataList = [];

    const params = new URLSearchParams();
    params.append("id", props.id);
    params.append("from", props.from);
    params.append("to", props.to);

    const response = await axios({
      method: "post",
      url: link,
      data: params,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    });
    // const data = await response.data;

    // return data === undefined ? null : data;
    const json = await response.data;

    if (json.success) {
      dataList = json.data.map((item) => {
        return new Models.TruckDateRangeModel(item);
      });
      return dataList;
    } else {
      return { success: json.success };
    }
  }
);

const truckStatsRangeAdapter = createEntityAdapter({});

export const { selectAll: selectTruckStats, selectById: selectTruckStatsById } =
truckStatsRangeAdapter.getSelectors((state) => state.truckStats);

const Slice = createSlice({
  name: "truckStats",
  initialState: truckStatsRangeAdapter.getInitialState({
    searchText: "",
  }),
  reducers: {
    setTruckStatsSearchText: {
      reducer: (state, action) => {
        state.searchText = action.payload;
      },
      prepare: (event) => ({ payload: event.target.value || "" }),
    },
  },
  extraReducers: {
    [getTruckStatRange.fulfilled]: truckStatsRangeAdapter.setAll,
    // [removeMerchants.fulfilled]: (state, action) =>
    //   trucksAdapter.removeMany(state, action.payload),
  },
});

export const { setTruckStatsSearchText } = Slice.actions;

export const selectTruckStatsSearchText = ({ truckStats }) => truckStats.searchText;

export default Slice.reducer;
