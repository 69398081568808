import React from "react";
import { Progress } from "rsuite";
import "rsuite/dist/rsuite.min.css";
import "./style.css";

const OilGuage = ({ value }) => {
  let color;
  if (value <= 20) {
    color = "red";
  } else if (value <= 50) {
    color = "yellow";
  } else {
    color = "green";
  }

  return (
    <div className="App">
      <div className="vertical-progress-with-scale">
        <div className="scale">
          {[...Array(11)].map((_, index) => {
            const number = 10 * (10 - index);
            return <span key={index}>{number}</span>;
          })}
        </div>
        <div className="vertical-progress-line-content">
          <Progress.Line
            style={{ height: "168px" }}
            percent={value}
            vertical={true}
            strokeColor={color}
          />
        </div>
      </div>
    </div>
  );
};

export default OilGuage;
