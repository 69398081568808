export default class TruckDateRangeModel {
  constructor(item) {
    this.id = item?.ref;
    this.truck_id = item?.id;
    this.date = item?.date;
    this.period = item?.period;
    this.initial_milage_per_period = item?.initial_milage_per_period;
    this.last_milage_per_period = item?.last_milage_per_period;
    this.initial_oilres_per_period = item?.initial_oilres_per_period;
    this.last_oilres_per_period = item?.last_oilres_per_period;
    this.initial_oillvl_per_period = item?.initial_oillvl_per_period;
    this.last_oillvl_per_period = item?.last_oillvl_per_period;
    this.initial_oilpercent_per_period = item?.initial_oilpercent_per_period;
    this.last_oilpercent_per_period = item?.last_oilpercent_per_period;
    this.total_idling_seconds_per_period = item?.total_idling_seconds_per_period;
    this.total_oil_consumed_per_period = item?.total_oil_consumed_per_period;
    this.total_oil_consumed_lvl_per_period = item?.total_oil_consumed_lvl_per_period;
    this.total_oil_consumed_percent_per_period = item?.total_oil_consumed_percent_per_period;
    this.total_oil_added_per_period = item?.total_oil_added_per_period;
    this.total_oil_added_lvl_per_period = item?.total_oil_added_lvl_per_period;
    this.total_oil_added_percent_per_period = item?.total_oil_added_percent_per_period;
    this.caseUnit = item?.caseUnit;
    this.fuel_capacity = item?.fuel_capacity;
    this.branch = item?.branch;
  }
}
