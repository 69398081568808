// import FuseUtils from "@fuse/utils";
import _ from "@lodash";
import Base64 from "crypto-js/enc-base64";
import HmacSHA256 from "crypto-js/hmac-sha256";
import Utf8 from "crypto-js/enc-utf8";
import jwtDecode from "jwt-decode";

const jwtSecret = "mewahtransgps2023winnercloudstudio3";

function base64url(source) {
  let encodedSource = Base64.stringify(source);

  encodedSource = encodedSource.replace(/=+$/, "");

  encodedSource = encodedSource.replace(/\+/g, "-");
  encodedSource = encodedSource.replace(/\//g, "_");

  return encodedSource;
}

function generateJWTToken(tokenPayload) {
  const header = {
    alg: "HS256",
    typ: "JWT",
  };

  const date = new Date();
  const iat = Math.floor(date.getTime() / 1000); // initial time
  const exp = Math.floor(date.setDate(date.getDate() + 7) / 1000); // expiry date

  const payload = {
    iat,
    iss: "MewahTrans",
    exp,
    ...tokenPayload,
  };

  const stringifiedHeader = Utf8.parse(JSON.stringify(header));
  const encodedHeader = base64url(stringifiedHeader);

  const stringifiedPayload = Utf8.parse(JSON.stringify(payload));
  const encodedPayload = base64url(stringifiedPayload);

  let signature = `${encodedHeader}.${encodedPayload}`;
  signature = HmacSHA256(signature, jwtSecret);
  signature = base64url(signature);

  // Build and return the token
  return `${encodedHeader}.${encodedPayload}.${signature}`;
}

export { base64url, generateJWTToken };
