import React, {
  useState,
  // useMemo,
  useCallback,
  useEffect,
  // useRef,
} from "react";
// import _, { set } from "lodash";
import {
  Header,
  Sidebar,
  // MapInfoWindow,
  SplashScreen,
  Maps,
  // LoadingSpinner,
} from "@components";
import { TruckDetails } from "./components";
import {
  Grid,
  Box,
  // SwipeableDrawer, 
  // Typography 
} from "@mui/material";
import { RxCross1 } from "react-icons/rx";
import { GrMenu } from "react-icons/gr";
import "./style.css";
import { useDispatch, useSelector } from "react-redux";
import {
  getTrucks,
  selectTrucks,
  selectTrucksSearchText,
} from "@slices/trucksSlice";
// import TruckRight from "@assets/images/LORRY1.png";
// import TruckLeft from "@assets/images/LORRY2.png";
import { DISABLEMAPS } from "@constants";
import { BaseUtils } from "@utils";
import { useWarning } from "../../provider/WarningContext";

const LocationPage = ({ isGoogleMapsLoaded }) => {
  const { mapsRefreshTimer } = useWarning();
  const dispatch = useDispatch();
  const [trackDetails, setTrackDetails] = useState(false);
  const [state, setState] = useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const trucks = useSelector(selectTrucks);
  const searchText = useSelector(selectTrucksSearchText);

  const [data, setData] = useState(trucks);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(0);
  // const [rowsPerPage, setRowsPerPage] = useState(10);
  const [trucksData, setTrucksData] = useState([]);
  // const [mapsData, setMapsData] = useState([]);
  const [selectedMarkers, setSelectedMarkers] = useState([]);
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  // const [mapStop, setMapStop] = useState(false);
  const [activeTab, setActiveTab] = useState(1);
  const [dataSets, setDataSets] = useState(false);
  // const [shouldRefreshMap, setShouldRefreshMap] = useState(true);

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  useEffect(() => {
    dispatch(getTrucks()).then(() => {
      setLoading(false);
      // if (!isDataLoaded) {
      //   setIsDataLoaded(true);
      // }
    });
  }, [dispatch]);

  useEffect(() => {
    if (data.length > 0) {
      if (!isDataLoaded) {
        setSelectedMarkers(data.map((item) => item.id));
        setIsDataLoaded(true);
      }
    }
  }, [data, isDataLoaded]);

  useEffect(() => {
    if (searchText.length !== 0) {
      setData(BaseUtils.filterArrayByString(trucks, searchText));
      setPage(0);
    } else {
      setData(trucks);
    }
  }, [trucks, searchText]);

  /*** refresh data and map */
  useEffect(() => {
    const interval = setInterval(() => {
      dispatch(getTrucks());
    }, mapsRefreshTimer);

    return () => clearInterval(interval);
  }, [dispatch, mapsRefreshTimer]);

  const onRefreshData = useCallback(() => {
    return dispatch(getTrucks());
  }, [dispatch]);

  useEffect(() => {
    if (data.length > 0) {
      setTrucksData(data);
    }
  }, [data]);

  if (loading) {
    return (
      <div className="flex items-center justify-center h-full">
        <SplashScreen />
      </div>
    );
  }

  return (
    <React.Fragment>
      <Header setTrackDetails={setTrackDetails} />
      <Grid container>
        <Grid item xs={12} md={2.5}>
          <Box className="home_sidebar_box">
            <Sidebar
              data={data}
              setTrackDetails={setTrackDetails}
              selectedMarkers={selectedMarkers}
              setSelectedMarkers={setSelectedMarkers}
              activeTab={activeTab}
              setActiveTab={setActiveTab}
            />
          </Box>
          <GrMenu
            className="home_drawer_btn"
            onClick={toggleDrawer("left", true)}
          />
        </Grid>
        <Grid
          item
          xs={12}
          md={9.5}
          className={
            trackDetails
              ? "location_page_left_container"
              : "location_page_left_container_withOutPadding"
          }
        >
          {trackDetails ? (
            <TruckDetails
              setTrackDetails={setTrackDetails}
              data={data}
              trackDetails={trackDetails}
              activeTab={activeTab}
              setActiveTab={setActiveTab}
              onRefreshData={onRefreshData}
            />
          ) : !DISABLEMAPS ? (
            <span>
              <Maps
                data={trucksData.filter((item) =>
                  selectedMarkers.includes(item.id)
                )}
                // onClickFunc={setTrackDetails}
                setTrackDetails={setTrackDetails}
                markersWindowHover
                markersOnClick
                customMarker={{
                  // left_image: TruckLeft,
                  // right_image: TruckRight,
                  // other: "customized",
                }}
                markersLabel
                onRefreshData={onRefreshData}
              />
            </span>
          ) : (
            <span>
              Map <br />
              Data: {JSON.stringify(trucksData)}
            </span>
          )}
        </Grid>
      </Grid>
      {/* <div>
        {["left"].map((anchor) => (
          <React.Fragment key={anchor}>
            <SwipeableDrawer
              anchor={anchor}
              open={state[anchor]}
              onClose={toggleDrawer(anchor, false)}
              onOpen={toggleDrawer(anchor, true)}
              PaperProps={{
                sx: { width: "300px" },
              }}
            >
              {list(anchor)}
            </SwipeableDrawer>
          </React.Fragment>
        ))}
      </div> */}
    </React.Fragment>
  );
};
export default LocationPage;
