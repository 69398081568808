import React, { useState } from "react";
import { Grid, Button } from "@mui/material";
import {
  Header,
} from "@components";
import "./style.css";
import { Reports, MonitoringLogs } from './components';
import _, { set } from "lodash";

// import faker from 'faker';

const ReportPage = () => {
  const [activeTab, setActiveTab] = useState(1);
  const [tabs, setTabs] = useState([
    {
      _id: 1,
      title: "Reports",
      is_active: true,
    },
    {
      _id: 2,
      title: "Monitoring Logs",
      is_active: false,
    },
  ]);

  return (
    <React.Fragment>
      <Header />
      <Grid container className="report_page_container">
        <Grid item xs={12} className="setting_tabs_container">
          <Grid container>
            {tabs?.map((item) => (
              <Grid item xs={12} md={(12 / tabs.length)} key={item?._id}>
                <Grid container>
                  <Grid item xs={0} md={0.5}></Grid>
                  <Grid item xs={12} md={11}>
                    <Button
                      className={
                        activeTab === item?._id
                          ? "setting_tab_active"
                          : "setting_tab"
                      }
                      onClick={() => setActiveTab(item._id)}
                    >
                      {item?.title}
                    </Button>
                  </Grid>
                  <Grid item xs={0} md={0.5}></Grid>
                </Grid>
              </Grid>
            ))}
          </Grid>
        </Grid>
        <Grid item xs={12}>
          {activeTab === 1 ? (<Reports />) : (<MonitoringLogs />)}
        </Grid>
      </Grid>
    </React.Fragment>
  );
};
export default ReportPage;
