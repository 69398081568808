const sec2Time = (secTime) => {
  const secondTime = parseFloat(secTime);
  const hours = Math.floor(secondTime / 3600);
  const minutes = Math.floor((secondTime - hours * 3600) / 60);
  const seconds = secondTime - hours * 3600 - minutes * 60;

  const hoursStr = hours === 0 ? `` : `${hours}h`;
  const minutesStr = minutes === 0 ? `` : `${minutes}m`;
  const secondsStr = seconds === 0 ? `` : `${seconds}s`;
  return hoursStr + ' ' + minutesStr + ' ' + secondsStr;
};

export default sec2Time;
