import React, { useEffect, useState } from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import logo from "@assets/images/appicon.png";
import { Link as RouterLink, MemoryRouter } from "react-router-dom";
import { StaticRouter } from "react-router-dom/server";
import _ from "@lodash";
import jwtService from "../../../authentication/services/jwtService";
import "./styles.css";
import { DialogModal } from "@components";
import { COLORS } from "@constants";
import packageInfo from "../../../../package.json";

function Copyright(props) {
	return (
		<Typography
			variant="body2"
			color="text.secondary"
			align="center"
			{...props}>
			{"Copyright" +
				" \u00a9 " +
				"  Mewahtrans GPS v" +
				packageInfo.version +
				" " +
				packageInfo.company +
				" " +
				new Date().getFullYear()}
			{"."}
		</Typography>
	);
}

const schema = yup.object().shape({
	email: yup.string().required("You must enter an email/login ID"),
	password: yup
		.string()
		.required("Please enter your password.")
		.min(8, "Password is too short - must be at least 8 chars."),
});

const defaultValues = {
	email: "",
	password: "",
	remember: false,
};

const defaultTheme = (theme) =>
	createTheme({
		...theme,
		components: {
			MuiInputBase: {
				styleOverrides: {
					root: {
						background: "white !important",
						height: "45px",
						boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25) !important",
						padding: "0 10px !important",
					},
				},
			},
			MuiButtonBase: {
				styleOverrides: {
					root: {
						height: "45px",
						boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25) !important",
					},
				},
			},
			MuiCheckbox: {
				styleOverrides: {
					root: {
						height: "15px !important",
						boxShadow: "0px 4px 4px rgba(0, 0, 0, 0)!important",
					},
				},
			},
			MuiInputLabel: {
				styleOverrides: {
					outlined: {
						transform: "translate(14px, 50%) scale(1)", // Adjust the initial position
						"&.MuiInputLabel-shrink": {
							transform: "translate(14px, -6px) scale(0.75)", // Adjust the position when shrunk (label is smaller and moved up)
						},
					},
				},
			},
		},
	});

export default function SignInSide() {
	const { control, formState, setValue, handleSubmit } = useForm({
		mode: "onChange",
		defaultValues,
		resolver: yupResolver(schema),
	});
	const account = JSON.parse(localStorage.getItem("rememberMeAccount"));
	const password = JSON.parse(localStorage.getItem("rememberMePassword"));
	const [isDialogOpen, setIsDialogOpen] = useState(false);
	const [errorMsg, setErrorMsg] = useState("");

	const { isValid, dirtyFields, errors, touchedFields, setError } = formState;

	useEffect(() => {
		// setValue('email', 'admin@fusetheme.com', { shouldDirty: true, shouldValidate: true });
		// setValue('password', 'admin', { shouldDirty: true, shouldValidate: true });
		const rememberCheck = account ? true : false;
		setValue("email", account, { shouldDirty: true, shouldValidate: true });
		setValue("password", password, { shouldDirty: true, shouldValidate: true });
		setValue("remember", rememberCheck, {
			shouldDirty: true,
			shouldValidate: false,
		});
	}, [setValue]);

	function onSubmit({ email, password, remember }) {
		setErrorMsg("");
		jwtService
			.signInWithEmailAndPassword(email, password, true)
			.then((user) => {
				// No need to do anything, user data will be set at app/auth/AuthContext
				if (remember) {
					localStorage.setItem("rememberMeAccount", JSON.stringify(email));
					localStorage.setItem("rememberMePassword", JSON.stringify(password));
				} else {
					localStorage.setItem("rememberMeAccount", JSON.stringify(""));
					localStorage.setItem("rememberMePassword", JSON.stringify(""));
				}
			})
			.catch((_errors) => {
				const errorMessages = _errors.map((error) => (
					<React.Fragment key={error.message}>
						{error.message}
						<br />
					</React.Fragment>
				));
				setErrorMsg(errorMessages);
				setIsDialogOpen(true);
			});
	}

	const handleModalStats = () => {
		setIsDialogOpen(false);
	};

	// const handleSubmit = (event) => {
	//   event.preventDefault();
	//   const data = new FormData(event.currentTarget);
	//   console.log({
	//     email: data.get("email"),
	//     password: data.get("password"),
	//   });
	// };

	return (
		<ThemeProvider theme={defaultTheme}>
			{isDialogOpen && (
				<DialogModal
					// title="Route History"
					message={errorMsg}
					handleModalStats={handleModalStats}
					isDialogOpen={isDialogOpen}
					icon={{
						icon: "bxs:error-alt",
						color: COLORS.red,
					}}
					customHeight={"28%"}
				/>
			)}
			<Grid
				container
				component="main"
				sx={{ height: "100vh" }}>
				<CssBaseline />
				<Grid
					item
					xs={false}
					sm={4}
					md={7}
					sx={{
						// backgroundImage: "url(https://source.unsplash.com/random?wallpapers)",
						backgroundRepeat: "no-repeat",
						backgroundColor: (t) =>
							t.palette.mode === "light" ? t.palette.grey[50] : t.palette.grey[900],
						backgroundSize: "cover",
						backgroundPosition: "center",
						// position: 'relative',
					}}
					className="login-image"></Grid>
				<Grid
					item
					xs={12}
					sm={8}
					md={5}
					component={Paper}
					elevation={6}
					square
					style={{
						display: "flex",
						alignItems: "center",
						textAlign: "right",
						justifyContent: "center",
					}}
					className="login-container">
					<Box
						sx={{
							my: 8,
							mx: 4,
							display: "flex",
							flexDirection: "column",
							alignItems: "center",
						}}>
						<Avatar
							sx={{ width: 110, height: 110, m: 1, bgcolor: "secondary.main" }}
							src={logo}
						/>
						{/* <LockOutlinedIcon /> */}
						{/* <img className="w-48" src={logo} alt="logo" /> */}
						{/* </Avatar> */}
						<Typography
							component="h1"
							variant="h5"
							className="signin-text">
							Sign in
						</Typography>
						{/* <form name="loginForm" noValidate onSubmit={handleSubmit(onSubmit)}> */}
						<Box
							component="form"
							noValidate
							onSubmit={handleSubmit(onSubmit)}
							// sx={{ mt: 1 }}
						>
							<Controller
								name="email"
								control={control}
								render={({ field }) => (
									<TextField
										{...field}
										margin="normal"
										required
										fullWidth
										id="email"
										label="Email/Login ID"
										name="email"
										// autoComplete="email"
										error={!!errors.email}
										helperText={errors?.email?.message}
										// autoFocus
										className="textfield-height"
										variant="outlined"
									/>
								)}
							/>
							<Controller
								name="password"
								control={control}
								render={({ field }) => (
									<TextField
										{...field}
										margin="normal"
										required
										fullWidth
										name="password"
										label="Password"
										type="password"
										id="password"
										// autoComplete="current-password"
										className="textfield-spacing textfield-height"
										error={!!errors.password}
										helperText={errors?.password?.message}
										variant="outlined"
									/>
								)}
							/>
							<FormControlLabel
								className="button-spacing"
								control={
									<Controller
										name="remember"
										control={control}
										render={({ field }) => (
											<Checkbox
												{...field}
												checked={field.value}
												color="primary"
											/>
										)}
									/>
								}
								label="Remember me"
							/>
							<Button
								type="submit"
								fullWidth
								disabled={_.isEmpty(dirtyFields) || !isValid}
								variant="contained"
								sx={{ mt: 3, mb: 2 }}
								className="button-spacing">
								Sign In
							</Button>
							<Grid
								container
								className="button-spacing">
								<Grid
									item
									xs
									style={{ textAlign: "left" }}>
									<Link
										component={RouterLink}
										to="/forgot-password"
										variant="body2">
										Forgot password?
									</Link>
								</Grid>
								{/* <Grid item>
                  <Link component={RouterLink} to="/signup" variant="body2">
                    {"Don't have an account? Sign Up"}
                  </Link>
                </Grid> */}
							</Grid>
							<Copyright
								sx={{ mt: 5 }}
								className="copyright-spacing"
							/>
						</Box>
						{/* </form> */}
					</Box>
				</Grid>
			</Grid>
		</ThemeProvider>
	);
}
