import * as React from "react";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { SplashScreen } from "@components";
// import { showMessage } from "app/store/fuse/messageSlice";
import { logoutUser, setUser } from "@slices/userSlice";
import jwtService from "./services/jwtService";
import { Snackbar } from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import Slide from "@mui/material/Slide";
import { styled } from "@mui/material/styles";
import { useNavigate } from 'react-router-dom';

const AuthContext = React.createContext();

const StyledAlert = styled(MuiAlert)(({ theme }) => ({
  // Add your custom styles here
  justifyContent: 'space-between',
  '& .MuiAlert-icon': {
    margin: '0px 9px 0px 11px !important',
  },
  '& .MuiAlert-message': {
    flex: 1,
  },
  '& .MuiAlert-action': {
    margin: '0px 11px 0px 9px !important',
  },
  // More styles...
}));

const Alert = React.forwardRef((props, ref) => (
  <StyledAlert elevation={6} variant="filled" ref={ref} {...props} />
));

function AuthProvider({ children }) {
  const [isAuthenticated, setIsAuthenticated] = useState(undefined);
  const [waitAuthCheck, setWaitAuthCheck] = useState(true);
  const dispatch = useDispatch();
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("info");
  const navigate = useNavigate();

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  useEffect(() => {
    jwtService.on("onAutoLogin", () => {
      // dispatch(showMessage({ message: "Signing in with Token" }));
      // console.log("message: Signing in with Token");

      /**
       * Sign in and retrieve user data with stored token
       */
      jwtService
        .signInWithToken()
        .then((user) => {
          success(user, "Signed in with Token");
        })
        .catch((error) => {
          setSnackbarSeverity("error");
          pass(error.message);
        });
    });

    jwtService.on("onLogin", (user) => {
      // console.log("onLogin");
      success(user, "Signed in");
    });

    jwtService.on("onLogout", () => {
      // console.log("onLogout");
      setSnackbarSeverity("warning");
      pass("Signed out");

      dispatch(logoutUser());
    });

    jwtService.on("onAutoLogout", (message) => {
      // console.log("onAutoLogout");
      setSnackbarSeverity("warning");
      pass(message);

      dispatch(logoutUser());
    });

    jwtService.on("onNoAccessToken", () => {
      // console.log("onNoAccessToken");
      setSnackbarSeverity("warning");
      pass();
    });

    jwtService.on("onResetPassword", (message) => {
      // console.log("onResetPassword");
      if(message.toLowerCase().includes("success")) {
        setSnackbarSeverity("info");
        pass(message);
        navigate("/sign-in");
      }
      else {
        setSnackbarSeverity("warning");
        pass(message);
      }
    });
    
    jwtService.on("onRegisterAccount", (message) => {
      // console.log("onResetPassword");
      if(message.toLowerCase().includes("success")) {
        setSnackbarSeverity("info");
        pass(message);
        // navigate("/");
      }
      else {
        setSnackbarSeverity("warning");
        pass(message);
      }
    });

    jwtService.init();

    function success(user, message) {
      if (message) {
        // dispatch(showMessage({ message }));
        // console.log(message);
        setSnackbarSeverity("success");
        setSnackbarMessage(message);
        setSnackbarOpen(true);
      }

      Promise.all([
        dispatch(setUser(user)),
        // You can receive data in here before app initialization
      ]).then((values) => {
        setWaitAuthCheck(false);
        setIsAuthenticated(true);
      });
    }

    function pass(message) {
      if (message) {
        // dispatch(showMessage({ message }));
        // console.log(message);
        setSnackbarMessage(message);
        setSnackbarOpen(true);
      }

      setWaitAuthCheck(false);
      setIsAuthenticated(false);
    }
  }, [dispatch]);

  return waitAuthCheck ? (
    <SplashScreen />
  ) : (
    <AuthContext.Provider value={{ isAuthenticated }}>
      {children}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        sx={{ width: "50%" }}
        TransitionComponent={Slide}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={snackbarSeverity}
          sx={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            // justifyContent: "space-between",
            height: "50px",
          }}
        >
          <span style={{ flexGrow: 1, paddingRight: "48px" }}>
            {snackbarMessage}
          </span>
        </Alert>
      </Snackbar>
    </AuthContext.Provider>
  );
}

function useAuth() {
  const context = React.useContext(AuthContext);
  if (context === undefined) {
    throw new Error("useAuth must be used within a AuthProvider");
  }
  return context;
}

export { AuthProvider, useAuth };
