// export default App;
import React, {
  useEffect,
  useState,
  useCallback,
  Fragment,
  useRef,
} from "react";
import _ from "lodash";
import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import {
  LocationPage,
  ReportPage,
  SettingPage,
  PageNotFound,
  SignOutPage,
  SignUpPage,
  SignInSide,
  ForgotPasswordPage,
} from "./pages";
import { useJsApiLoader } from "@react-google-maps/api";
import AppNotification from "./AppNotification";
import Authorization from "./authentication/Authorization";
import { selectUser } from "./@slices/userSlice";
import { useSelector } from "react-redux";
import withAppProviders from "./provider/withProvider";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { AppProvider } from "./provider/AppProvider";
import { AuthProvider } from "./authentication/AuthContext";
import { BrowserRouter } from "./provider/BrowserRouter";
import { SplashScreen } from "@components";

const googleMapsLibraries = ["drawing"];

const theme = createTheme({});

const App = () => {
  const user = useSelector(selectUser);
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_API_KEY,
    libraries: googleMapsLibraries,
  });
  // {
  //   console.log(user);
  // }

  // if (!isLoaded) {
  //   return <p>Loading Google Map...</p>;
  // }
  return (
    // <React.Fragment>
    //   <Router>
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <AuthProvider>
          <AppProvider>
            <Authorization userRole={user.role} loginRedirectUrl={"/location"}>
              <AppNotification />
              <Routes>
                <Route path="/" element={<LocationPage />} />
                <Route path="/location" element={<LocationPage />} />
                <Route path="/sign-in" element={<SignInSide />} />
                <Route path="/sign-out" element={<SignOutPage />} />
                <Route path="/sign-up" element={<SignUpPage />} />
                <Route
                  path="/forgot-password"
                  element={<ForgotPasswordPage />}
                />
                {/* <Route path="/signup" element={<SignUpPage />} /> */}
                <Route path="/report" element={<ReportPage />} />
                <Route path="/setting" element={<SettingPage />} />
                <Route path="/setting" element={<SettingPage />} />
                <Route path="*" element={<PageNotFound />} />
                <Route path="/loading" element={<SplashScreen />} />
              </Routes>
            </Authorization>
          </AppProvider>
        </AuthProvider>
      </BrowserRouter>
    </ThemeProvider>
    //   </Router>
    // </React.Fragment>
  );
};

export default withAppProviders(App)();
