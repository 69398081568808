import React, { useEffect, useState } from "react";
import {
	Grid,
	Typography,
	Box,
	Button,
	TextField,
	Checkbox,
} from "@mui/material";
import { AccordionWithToggle, Dropdown } from "@components";
import "./style.css";
import { useWarning } from "../../../provider/WarningContext";
import doremi from "@assets/sounds/doremi.mp3";
import dot from "@assets/sounds/dot.mp3";
import realistic_dot from "@assets/sounds/realistic_dot.mp3";
import sharp_tone from "@assets/sounds/sharp_tone.wav";
import xylophone from "@assets/sounds/xylophone.mp3";
import packageInfo from "../../../../package.json";
import { MAP_REFRESH_RATE } from "@constants";

const soundOptions = [
	{
		title: "",
		value: "doremi",
		label: "Do Re Mi",
	},
	{
		title: "",
		value: "dot",
		label: "Dot",
	},
	{
		title: "",
		value: "realistic_dot",
		label: "Realistic Dot",
	},
	{
		title: "",
		value: "sharp_tone",
		label: "Sharp Tone",
	},
	{
		title: "",
		value: "xylophone",
		label: "Xylophone",
	},
];

const soundConverter = (sound) => {
	switch (sound) {
		case "doremi":
			return doremi;
		case "dot":
			return dot;
		case "realistic_dot":
			return realistic_dot;
		case "sharp_tone":
			return sharp_tone;
		case "xylophone":
			return xylophone;
		default:
			return null;
	}
};

const SoundSystem = () => {
	const {
		overSpeedWarning,
		setOverSpeedWarning,
		overSpeedLimit,
		setOverSpeedLimit,
		idleLimitWarning,
		setIdleLimitWarning,
		arrivedWarning,
		setArrivedWarning,
		overSpeedWarningSound,
		setOverSpeedWarningSound,
		idleLimitWarningSound,
		setIdleLimitWarningSound,
		mapsRefreshTimer,
		setMapsRefreshTimer,
		fuelTheftWarning,
		setFuelTheftWarning,
		fuelTheftWarningSound,
		setFuelTheftWarningSound,
	} = useWarning();

	const [speedSound, setSpeedSound] = useState(overSpeedWarningSound);

	const [speedCheckboxChecked, setSpeedCheckboxChecked] = useState(
		overSpeedWarningSound === null ? false : true
	);

	const [idleSound, setIdleSound] = useState(idleLimitWarningSound);

	const [idleCheckboxChecked, setIdleCheckboxChecked] = useState(
		idleLimitWarningSound === null ? false : true
	);

	const [fuelSound, setFuelSound] = useState(fuelTheftWarningSound);

	const [fuelCheckboxChecked, setFuelCheckboxChecked] = useState(
		fuelTheftWarningSound === null ? false : true
	);

	const handleSpeedCheckboxChange = (event) => {
		if (event.target.checked === false) {
			setSpeedCheckboxChecked(false);
			setOverSpeedWarningSound(null);
			setSpeedSound(null);
		} else {
			setSpeedCheckboxChecked(true);
			setSpeedSound("doremi");
		}
	};

	const handleIdleCheckboxChange = (event) => {
		if (event.target.checked === false) {
			setIdleCheckboxChecked(false);
			setIdleLimitWarningSound(null);
			setIdleSound(null);
		} else {
			setIdleCheckboxChecked(true);
			setIdleSound("dot");
		}
	};

	const handleFuelCheckboxChange = (event) => {
		if (event.target.checked === false) {
			setFuelCheckboxChecked(false);
			setFuelTheftWarningSound(null);
			setFuelSound(null);
		} else {
			setFuelCheckboxChecked(true);
			setFuelSound("sharp_tone");
		}
	};

	useEffect(() => {
		setOverSpeedWarningSound(speedSound);
		if (speedSound !== null && overSpeedWarningSound !== speedSound) {
			const audio = new Audio(soundConverter(speedSound));
			audio.play();
		}
	}, [speedSound]);

	useEffect(() => {
		setIdleLimitWarningSound(idleSound);
		if (idleSound !== null && idleLimitWarningSound !== idleSound) {
			const audio = new Audio(soundConverter(idleSound));
			audio.play();
		}
	}, [idleSound]);

	useEffect(() => {
		setFuelTheftWarningSound(fuelSound);
		if (fuelSound !== null && idleLimitWarningSound !== fuelSound) {
			const audio = new Audio(soundConverter(fuelSound));
			audio.play();
		}
	}, [fuelSound]);

	const handleOverSpeedToggleChange = (event) => {
		setOverSpeedWarning(event.target.checked);
	};

	const handleIdleLimitToggleChange = (event) => {
		setIdleLimitWarning(event.target.checked);
	};

	const handleFuelToggleChange = (event) => {
		setFuelTheftWarning(event.target.checked);
	};

	const handleArrivedToggleChange = (event) => {
		setArrivedWarning(event.target.checked);
	};

	const handleMapsRefreshTimerReset = () => {
		setMapsRefreshTimer(MAP_REFRESH_RATE);
	};

	return (
		<React.Fragment>
			<Grid
				container
				className="SoundSystem_container">
				<Grid
					item
					xs={12}
					className="line"
				/>
				<Grid
					item
					xs={6}
					className="SoundSystem_headings">
					Condition
				</Grid>
				<Grid
					item
					xs={6}
					className="SoundSystem_headings">
					Status
				</Grid>
				<Grid
					item
					xs={12}
					className="line"
				/>
				<Grid
					item
					xs={12}>
					<AccordionWithToggle
						hideArrow={true}
						expanded={false}
						summaryChildren={
							<Grid container>
								<Grid
									item
									xs={0.5}
									sm={1.25}
									md={1.2}></Grid>
								<Grid
									item
									xs={5.5}
									style={{ display: "flex", alignItems: "center" }}
									sm={6.4}
									md={6.8}>
									<Typography>
										{"Maps Refresh Time (default: 3 mins)"}
									</Typography>
								</Grid>
								<Grid
									item
									xs={2}
									sm={1.2}
									md={0.8}
									style={{ display: "flex", alignItems: "center" }}>
									<Button
										variant="contained"
										onClick={handleMapsRefreshTimerReset}>
										reset
									</Button>
								</Grid>
								<Grid
									item
									xs={2.5}
									sm={1.8}
									md={1.2}>
									<TextField
										hiddenLabel
										className="text-input"
										fullWidth
										type="number"
										id="mapsRefreshTime"
										value={mapsRefreshTimer / 60000}
										onChange={(e) =>
											setMapsRefreshTimer(e.target.value * 60000)
										}
									/>
								</Grid>
								<Grid
									item
									xs={1.5}
									sm={1.35}
									md={2}
									style={{ display: "flex", alignItems: "center" }}>
									<Typography>mins</Typography>
								</Grid>
							</Grid>
						}
					/>
				</Grid>
				<Grid
					item
					xs={12}
					className="line"
				/>
				<Grid
					item
					xs={12}>
					<AccordionWithToggle
						hideArrow={true}
						expanded={overSpeedWarning}
						summaryChildren={
							<Grid container>
								<Grid
									item
									xs={0.5}
									sm={1.25}
									md={1.2}></Grid>
								<Grid
									item
									xs={8}
									style={{ display: "flex", alignItems: "center" }}
									sm={7.1}
									md={7}>
									<Typography>Over Speed Warning</Typography>
								</Grid>
								<Grid
									item
									xs={0.5}
									sm={0.5}
									md={0.6}></Grid>
								<Grid
									item
									xs={2.5}
									sm={1.8}
									md={1.2}>
									<Box className="SoundSystem_toggle_btn_box df ac">
										<label className="toggle">
											<input
												type="checkbox"
												checked={overSpeedWarning}
												onChange={handleOverSpeedToggleChange}
											/>
											<span className="slider" />
											<span
												className="labels"
												data-on="ON"
												data-off="OFF"
											/>
										</label>
									</Box>
								</Grid>
								<Grid
									item
									xs={0.5}
									sm={1.35}
									md={2}></Grid>
							</Grid>
						}>
						<Grid
							container
							className="accordion-open">
							{/* <Grid item xs={12}>
                <Grid container>
                  <Grid item xs={0.5} sm={1.25} md={6.3}></Grid>
                  <Grid
                    item
                    xs={4}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      textAlign: "right",
                      justifyContent: "flex-end",
                    }}
                    sm={6.5}
                    md={2}
                  >
                    <Typography>Speed Limit:</Typography>
                  </Grid>
                  <Grid item xs={0.5} sm={0.5} md={0.4}></Grid>
                  <Grid item xs={6.5} sm={2.5} md={2}>
                    <Box
                      sx={{
                        width: 500,
                        maxWidth: "100%",
                      }}
                    >
                      <TextField
                        hiddenLabel
                        className="text-input"
                        fullWidth
                        type="number"
                        id="overspeedlimit"
                        value={overSpeedLimit}
                        onChange={(e) => setOverSpeedLimit(e.target.value)}
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={0.5} sm={1.25} md={1.3}></Grid>
                </Grid>
              </Grid> */}
							<Grid
								item
								xs={12}>
								<Grid container>
									<Grid
										item
										xs={0.5}
										sm={1.25}
										md={7.15}></Grid>
									<Grid
										item
										xs={4}
										style={{
											display: "flex",
											alignItems: "center",
											textAlign: "right",
											justifyContent: "flex-end",
										}}
										sm={6.5}
										md={1.15}>
										<Checkbox
											checked={speedCheckboxChecked}
											onChange={handleSpeedCheckboxChange}
											inputProps={{ "aria-label": "controlled" }}
											className="checkbox_inFlex"
										/>
										<Typography>Sound:</Typography>
									</Grid>
									<Grid
										item
										xs={0.5}
										sm={0.5}
										md={0.4}></Grid>
									<Grid
										item
										xs={6.5}
										sm={2.5}
										md={2}>
										<Dropdown
											selections={soundOptions}
											placeholderTitle={"Select Sound"}
											value={speedSound}
											setValue={setSpeedSound}
											disabled={!speedCheckboxChecked}
										/>
									</Grid>
									<Grid
										item
										xs={0.5}
										sm={1.25}
										md={1.3}></Grid>
								</Grid>
							</Grid>
						</Grid>
					</AccordionWithToggle>
				</Grid>
				<Grid
					item
					xs={12}
					className="line"
				/>
				<Grid
					item
					xs={12}>
					<AccordionWithToggle
						hideArrow={true}
						expanded={idleLimitWarning}
						summaryChildren={
							<Grid container>
								<Grid
									item
									xs={0.5}
									sm={1.25}
									md={1.2}></Grid>
								<Grid
									item
									xs={8}
									style={{ display: "flex", alignItems: "center" }}
									sm={7.1}
									md={7}>
									<Typography>Idle more that 30 minutes</Typography>
								</Grid>
								<Grid
									item
									xs={0.5}
									sm={0.5}
									md={0.6}></Grid>
								<Grid
									item
									xs={2.5}
									sm={1.8}
									md={1.2}>
									<Box className="SoundSystem_toggle_btn_box df ac">
										<label className="toggle">
											<input
												type="checkbox"
												checked={idleLimitWarning}
												onChange={handleIdleLimitToggleChange}
											/>
											<span className="slider" />
											<span
												className="labels"
												data-on="ON"
												data-off="OFF"
											/>
										</label>
									</Box>
								</Grid>
								<Grid
									item
									xs={0.5}
									sm={1.35}
									md={2}></Grid>
							</Grid>
						}>
						<Grid
							container
							className="accordion-open">
							<Grid
								item
								xs={12}>
								<Grid container>
									<Grid
										item
										xs={0.5}
										sm={1.25}
										md={7.15}></Grid>
									<Grid
										item
										xs={4}
										style={{
											display: "flex",
											alignItems: "center",
											textAlign: "right",
											justifyContent: "flex-end",
										}}
										sm={6.5}
										md={1.15}>
										<Checkbox
											checked={idleCheckboxChecked}
											onChange={handleIdleCheckboxChange}
											inputProps={{ "aria-label": "controlled" }}
											className="checkbox_inFlex"
										/>
										<Typography>Sound:</Typography>
									</Grid>
									<Grid
										item
										xs={0.5}
										sm={0.5}
										md={0.4}></Grid>
									<Grid
										item
										xs={6.5}
										sm={2.5}
										md={2}>
										<Dropdown
											selections={soundOptions}
											placeholderTitle={"Select Sound"}
											value={idleSound}
											setValue={setIdleSound}
											disabled={!idleCheckboxChecked}
										/>
									</Grid>
									<Grid
										item
										xs={0.5}
										sm={1.25}
										md={1.3}></Grid>
								</Grid>
							</Grid>
						</Grid>
					</AccordionWithToggle>
				</Grid>
				<Grid
					item
					xs={12}
					className="line"
				/>
				<Grid
					item
					xs={12}>
					<AccordionWithToggle
						hideArrow={true}
						expanded={fuelTheftWarning}
						summaryChildren={
							<Grid container>
								<Grid
									item
									xs={0.5}
									sm={1.25}
									md={1.2}></Grid>
								<Grid
									item
									xs={8}
									style={{ display: "flex", alignItems: "center" }}
									sm={7.1}
									md={7}>
									<Typography>Fuel Theft Warning</Typography>
								</Grid>
								<Grid
									item
									xs={0.5}
									sm={0.5}
									md={0.6}></Grid>
								<Grid
									item
									xs={2.5}
									sm={1.8}
									md={1.2}>
									<Box className="SoundSystem_toggle_btn_box df ac">
										<label className="toggle">
											<input
												type="checkbox"
												checked={fuelTheftWarning}
												onChange={handleFuelToggleChange}
											/>
											<span className="slider" />
											<span
												className="labels"
												data-on="ON"
												data-off="OFF"
											/>
										</label>
									</Box>
								</Grid>
								<Grid
									item
									xs={0.5}
									sm={1.35}
									md={2}></Grid>
							</Grid>
						}>
						<Grid
							container
							className="accordion-open">
							<Grid
								item
								xs={12}>
								<Grid container>
									<Grid
										item
										xs={0.5}
										sm={1.25}
										md={7.15}></Grid>
									<Grid
										item
										xs={4}
										style={{
											display: "flex",
											alignItems: "center",
											textAlign: "right",
											justifyContent: "flex-end",
										}}
										sm={6.5}
										md={1.15}>
										<Checkbox
											checked={fuelCheckboxChecked}
											onChange={handleFuelCheckboxChange}
											inputProps={{ "aria-label": "controlled" }}
											className="checkbox_inFlex"
										/>
										<Typography>Sound:</Typography>
									</Grid>
									<Grid
										item
										xs={0.5}
										sm={0.5}
										md={0.4}></Grid>
									<Grid
										item
										xs={6.5}
										sm={2.5}
										md={2}>
										<Dropdown
											selections={soundOptions}
											placeholderTitle={"Select Sound"}
											value={fuelSound}
											setValue={setFuelSound}
											disabled={!fuelCheckboxChecked}
										/>
									</Grid>
									<Grid
										item
										xs={0.5}
										sm={1.25}
										md={1.3}></Grid>
								</Grid>
							</Grid>
						</Grid>
					</AccordionWithToggle>
				</Grid>
				<Grid
					item
					xs={12}
					className="line"
				/>
				{/* <Grid item xs={12}>
          <AccordionWithToggle
            hideArrow={true}
            expanded={arrivedWarning}
            summaryChildren={
              <Grid container>
                <Grid item xs={0.5} sm={1.25} md={1.2}></Grid>
                <Grid
                  item
                  xs={8}
                  style={{ display: "flex", alignItems: "center" }}
                  sm={7.1}
                  md={7}
                >
                  <Typography>Arrived Destination</Typography>
                </Grid>
                <Grid item xs={0.5} sm={0.5} md={0.6}></Grid>
                <Grid item xs={2.5} sm={1.8} md={1.2}>
                  <Box className="SoundSystem_toggle_btn_box df ac">
                    <label className="toggle">
                      <input
                        type="checkbox"
                        checked={arrivedWarning}
                        onChange={handleArrivedToggleChange}
                      />
                      <span className="slider" />
                      <span className="labels" data-on="ON" data-off="OFF" />
                    </label>
                  </Box>
                </Grid>
                <Grid item xs={0.5} sm={1.35} md={2}></Grid>
              </Grid>
            }
          />
        </Grid>
        <Grid item xs={12} className="line" /> */}
				<Grid
					item
					xs={12}
					style={{
						display: "flex",
						alignItems: "center",
						textAlign: "right",
						justifyContent: "center",
					}}
					className="copyright-layout">
					<Typography>
						{packageInfo.name +
							" v" +
							packageInfo.version +
							" \u00a9 " +
							packageInfo.company +
							" " +
							new Date().getFullYear()}
					</Typography>
				</Grid>
			</Grid>
		</React.Fragment>
	);
};
export default SoundSystem;
