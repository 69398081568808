import React from "react";
import { useNavigate } from "react-router-dom";
import { COLORS } from "@constants";

const PageNotFound = () => {
  const navigate = useNavigate();
  return (
    <React.Fragment>
      <div
        style={{
          width: "100%",
          height: "100vh",
          display: "grid",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: COLORS.blue,
        }}
      >
        <div style={{ textAlign: "center" }}>
        <img src={require("@assets/images/appicon.png")} style={{width:100,height:100}} />
          <h1>Page Not Found</h1> 
          <h2
            style={{
              color: COLORS.lightBlue,
              fontStyle: "italic",
              cursor: "pointer",
              textDecoration: "underline",
            }}
            onClick={() => navigate("/")}
          >
            Go to Home Page
          </h2>
        </div>
      </div>
    </React.Fragment>
  );
};

export default PageNotFound;
