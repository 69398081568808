/**** Open Strees Maps reverse Geocoding */
import axios from "axios";

function getAddress(json) {
	// Initialize an array to hold address components
	const parts = [];

	// List preferred address components in order of specificity
	const preferredComponents = ["village", "road", "suburb", "city"];

	// Check and add the first available preferred component
	for (const component of preferredComponents) {
		if (json.address[component]) {
			parts.push(json.address[component]);
			break; // Stop after adding the first available component
		}
	}

	// Add the city if it hasn't already been added as a preferred component
	if (!parts.includes(json.address.city) && json.address.city) {
		parts.push(json.address.city);
	}

	// Always add the state if it exists
	if (json.address.state) {
		parts.push(json.address.state);
	}

	// Return the constructed address, or the display name if no parts were added
	return parts.length > 0 ? parts.join(", ") : json.display_name;
}

export const Nominatim = async ({ lat, lng }) => {
	const link = `https://nominatim.mewahtrans.com.my/reverse?lat=${lat}&lon=${lng}&format=json`;
	// const link = `https://mewahgps.mewahtrans.com.my/nominatim/reverse?lat=${lat}&lon=${lng}&format=json`;
	var result = [];

	try {
		const response = await axios.get(link);
		const json = response.data;
		if (json?.address) {
			result["address"] = json.display_name;
			result["region"] = getAddress(json);
			// console.log(json);
			return result;
		} else {
			return "";
		}
	} catch (err) {
		// console.log(err);
		return "";
	}
};
