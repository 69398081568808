var CryptoJS = require("crypto-js");
const ALPHANUMERIC =
  "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";
var moment = require("moment-timezone");

// const Server = {
//   username: "",
//   password: "",
//   link: "https://smartconsumerapp.winnercloudstudio4.com/api/Server_API.php?",
// };

const generateSalt = () => {
  const length = 22;
  let randomStr = "";
  for (let i = 0; i < length; i++) {
    randomStr += ALPHANUMERIC.charAt(
      Math.floor(Math.random() * ALPHANUMERIC.length)
    );
  }
  return "$2a$10$" + randomStr;
};

const hashPassword = (password, salt) => {
  const hmac = CryptoJS.enc.Hex.stringify(CryptoJS.HmacSHA256(password, salt));
  return hmac;
};

const convertToTimestamp = (date) => {
  return new Date(date).getTime() / 1000;
};

const convertToDateString = (timestamp) => {
  var date = moment.unix(timestamp);
  date.tz("Asia/Kuala_Lumpur");
  return date.format();
};

const convertImagetoFile = (dataurl, filename) => {
  var arr = dataurl.split(","),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename, { type: mime });
};

export default {
  // Server,
  generateSalt,
  hashPassword,
  convertToTimestamp,
  convertToDateString,
  convertImagetoFile,
};
