import { BaseUtils } from "@utils";
import axios from "axios";
import { jwtDecode } from "jwt-decode";
import jwtServiceConfig from "./jwtServiceConfig";
import { JWT } from "@server-api";
import * as Config from "@slices/serverConfig";

/* eslint-disable camelcase */

class JwtService extends BaseUtils.EventEmitter {
  init() {
    this.setInterceptors();
    this.handleAuthentication();
  }

  setInterceptors = () => {
    axios.interceptors.response.use(
      (response) => {
        return response;
      },
      (err) => {
        return new Promise((resolve, reject) => {
          if (
            err.response?.status === 401 &&
            err.config &&
            !err.config.__isRetryRequest
          ) {
            // if you ever get an unauthorized response, logout the user
            this.emit("onAutoLogout", "Invalid access_token");
            this.setSession(null);
          }
          throw err;
        });
      }
    );
  };

  handleAuthentication = () => {
    const access_token = this.getAccessToken();

    if (!access_token) {
      this.emit("onNoAccessToken");

      return;
    }

    if (this.isAuthTokenValid(access_token)) {
      this.setSession(access_token);
      this.emit("onAutoLogin", true);
    } else {
      this.setSession(null);
      this.emit("onAutoLogout", "access_token expired");
    }
  };

  createUser = (data) => {
    return new Promise(async (resolve, reject) => {
      const link = Config.Server.link + "mode=add-user";
      const randomword = Config.generateSalt();
      const hashedPassword = Config.hashPassword(data.password, randomword);

      var postData = new FormData();
      postData.append("email", data.email);
      postData.append("displayName", data.displayName);
      postData.append("loginID", data.loginID);
      postData.append("password", hashedPassword);
      postData.append("randomword", randomword);
      postData.append("is_admin", "1");

      try {
        const response = await axios({
          method: "POST",
          url: link,
          data: postData,
        });

        const result = await response.data;
        console.log(result);

        if (result?.success) {
          this.emit("onRegisterAccount", result?.message);
        } else {
          this.emit("onRegisterAccount", result?.message);
        }
      } catch (error) {
        this.emit("onRegisterAccount", error);
      }
    });
  };

  signInWithEmailAndPassword = (email, password, remember) => {
    return new Promise(async (resolve, reject) => {
      const saltLink = Config.Server.link + "mode=getUserSalt";
      const link = Config.Server.link + "mode=admin-login";

      var postData = new FormData();
      postData.append("username", email);
      postData.append("is_admin", "1");

      try {
        const saltResponse = await axios({
          method: "post",
          url: saltLink,
          data: postData,
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        });

        var saltData = await saltResponse.data;

        if (saltData?.success && saltData?.data !== "") {
          const hashedPassword = Config.hashPassword(
            password,
            saltData?.data.salt
          );
          // console.log(saltData);

          const token = JWT.generateJWTToken({ id: saltData?.data.id });

          var formData = new FormData();
          formData.append("usernamelogin", email);
          formData.append("passwordlogin", hashedPassword);
          formData.append("credit", token);

          const response = await axios({
            method: "POST",
            url: link,
            data: formData,
          });

          const userData = await response.data;

          if (userData?.success) {
            const set = {
              // shortcuts: [
              //   "apps.calendar",
              //   "apps.mailbox",
              //   "apps.contacts",
              //   "apps.tasks",
              // ],
              role: userData?.data?.is_admin === "1" ? "admin" : "user",
            };
            const newData = { ...userData, ...set };
            remember && this.setSession(userData.data.token);
            resolve(newData);
            this.emit("onLogin", newData);
          } else {
            reject([
              {
                message: "Double check your email/login id",
                type: "email",
              },
              {
                message: "Double check your password",
                type: "password",
              },
            ]);
          }
        } else {
          reject([
            {
              message: "Email/Username not found",
              type: "email",
            },
          ]);
        }
      } catch (error) {
        reject([
          {
            message: "Unable login",
            type: "unknown",
          },
        ]);
      }
    });
  };

  signInWithToken = () => {
    return new Promise(async (resolve, reject) => {
      const link = Config.Server.link + "mode=token-login";
      const token = this.getAccessToken();

      var formData = new FormData();
      formData.append("credit", token);

      try {
        const response = await axios({
          method: "POST",
          url: link,
          data: formData,
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        });

        const userData = await response.data;
        const set = {
          role: "admin",
        };

        if (userData?.success) {
          const newData = { ...userData, ...set };
          this.setSession(userData.data.token);
          resolve(newData);
          this.emit("onLogin", newData);
        } else {
          reject([
            {
              message: userData.data.message,
              type: "email",
            },
          ]);
        }
      } catch (error) {
        reject([
          {
            message: "Unable token login",
            type: "unknown",
          },
        ]);
      }

      // axios
      //   .get(jwtServiceConfig.accessToken, {
      //     data: {
      //       credit: this.getAccessToken(),
      //     },
      //   })
      //   .then((response) => {
      //     if (response.data.user) {
      //       this.setSession(response.data.access_token);
      //       resolve(response.data.user);
      //     } else {
      //       this.logout();
      //       reject(new Error("Failed to login with token."));
      //     }
      //   })
      //   .catch((error) => {
      //     this.logout();
      //     reject(new Error("Failed to login with token."));
      //   });
    });
  };

  updateUserData = (user) => {
    return axios.post(jwtServiceConfig.updateUser, {
      user,
    });
  };

  setSession = (access_token) => {
    if (access_token) {
      localStorage.setItem("accountAccessToken", access_token);
      // axios.defaults.headers.common.Authorization = `Bearer ${access_token}`;
    } else {
      localStorage.removeItem("accountAccessToken");
      // delete axios.defaults.headers.common.Authorization;
    }
  };

  setRememberMe = (remember) => {
    if (remember) {
      localStorage.setItem("accountRememberMe", remember);
      // axios.defaults.headers.common.Authorization = `Bearer ${access_token}`;
    } else {
      localStorage.removeItem("accountRememberMe");
      // delete axios.defaults.headers.common.Authorization;
    }
  };

  logout = () => {
    this.setSession(null);
    this.emit("onLogout", "Logged out");
  };

  isAuthTokenValid = (access_token) => {
    if (!access_token) {
      return false;
    }
    const decoded = jwtDecode(access_token);
    const currentTime = Date.now() / 1000;
    if (decoded.exp < currentTime) {
      console.warn("access token expired");
      return false;
    }

    return true;
  };

  getAccessToken = () => {
    return window.localStorage.getItem("accountAccessToken");
  };

  getRememberMe = () => {
    return window.localStorage.getItem("accountRememberMe");
  };

  resetPassword = (email, password) => {
    return new Promise(async (resolve, reject) => {
      const link = Config.Server.link + "mode=set-forgetpassword";
      const randomword = Config.generateSalt();
      const hashedPassword = Config.hashPassword(password, randomword);

      var postData = new FormData();
      postData.append("email", email);
      postData.append("passwordtochange", hashedPassword);
      postData.append("randomword", randomword);
      postData.append("is_admin", "1");

      try {
        const response = await axios({
          method: "POST",
          url: link,
          data: postData,
        });

        const result = await response.data;

        if (result?.success) {
          this.emit("onResetPassword", result?.user);
        } else {
          this.emit("onResetPassword", result?.user);
        }
      } catch (error) {
        this.emit("onResetPassword", "Error: Try again later.");
      }
    });
  };
}

const instance = new JwtService();

export default instance;
